@import "~antd/dist/antd.css";

body {
	/* background: #d9d9d9; */
	/* background: #f5f5f5; */
	background: #e8e8e8;
	transition-duration: 0.2s;
}
hr {
	border: 0px;
	border-bottom: 1px solid;
}
a:focus {
	text-decoration: none;
}
.bg-primary {
	background-color: #4190f7;
}
.ant-layout {
	background: inherit;
}
.ant-card {
	color: #333;
}
.ant-table-row {
	cursor: pointer;
}
.ant-checkbox {
	margin-top: 5px;
	float: left;
}
.ant-table {
	color: #333;
}
.headerOrg {
	display: block;
}
.headerTitleText {
	max-width: 184px;
}
.mobileHeaderOrg {
	display: none;
}
.sideMenuIcon {
	display: none;
	opacity: 0;
}
.sideMenuOpen {
	left: 0px !important;
}
.sideMenuScreen {
	position: fixed;
	left: 0px;
	width: 100vw;
	height: 100vh;
	background: #000000a6;
	z-index: 1;
}
.sideMenu {
	position: fixed;
	z-index: 2;
	left: 0px;
	height: calc(100vh - 64px);
	overflow-y: auto;
}
.sideMenuLayout {
	padding-left: 200px;
}
.bottomNav {
	position: fixed;
	bottom: 0px;
	width: 100vw;
	height: 60px;
	background: #fff;
	display: none;
}
.bg-primary {
	background-color: #4190f7;
}
.iaLight .ant-input-group-addon {
	background: #fff;
	color: #333;
}
/* .ant-table td {
	white-space: nowrap;
} */
.cardTitleCenter .ant-card-head-title {
	display: inline;
	margin: auto;
}
.ant-card-actions {
	background: #f5f5f5;
}
.ant-card-actions > li {
	text-align: right;
}
.ant-upload.ant-upload-drag {
	border-color: #ccc;
}
.ant-card-head {
	border-color: #ccc;
}
.cardBodyPB0 .ant-card-body {
	padding-bottom: 0px;
}
.linkCard {
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.linkCard:hover {
	border-color: #1890ff;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.ant-table-body {
	/* min-width: 700px; */
	overflow-x: auto;
}
.ant-table-body table {
	min-width: 800px;
	/* overflow-x: auto; */
}
.ant-menu-horizontal > .ant-menu-item > a:hover {
	color: inherit;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
	background-color: #2962ff;
}
.ant-card-wider-padding .ant-card-head {
	padding: 0px 24px;
}
.ant-select-selection__choice__content > .hideWhenSelectedOption {
	display: none;
}
.ant-select-selection--multiple .ant-select-selection__choice {
	/* background: #e6f7ff; */
	background: #fff;
}
.ant-select-selection__choice__content {
	color: #2962ff;
}
.hideListContent > .ant-list-item-content {
	display: none;
}
.hidePictureList .ant-upload-list {
	display: none;
}
.ant-btn {
	border-color: #ccc;
}
.pictureCardCustom {
	border: 1px solid #e8e8e8;
	padding: 8px;
	border-radius: 4px;
	display: inline-block;
	max-width: 258px;
}
.pictureCardCustom img {
	max-width: 248px;
	max-height: 248px;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.successBtn {
	color: #fff;
	background-color: #52c41a; /*color-6*/
	border-color: #52c41a; /*color-6*/
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
	-webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}
.successBtn:hover,
.successBtn:focus {
	color: #fff;
	background-color: #73d13d; /*color-5*/
	border-color: #73d13d; /*color-5*/
}
.successBtn:active,
.successBtn.active {
	color: #fff;
	background-color: #389e0d; /*color-7*/
	border-color: #389e0d; /*color-7*/
}

.successBtn2 {
	color: #fff;
	background-color: #389e0d; /*color-7*/
	border-color: #389e0d; /*color-7*/
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
	-webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}
.successBtn2:hover,
.successBtn2:focus {
	color: #fff;
	background-color: #52c41a; /*color-6*/
	border-color: #52c41a; /*color-6*/
}
.successBtn2:active,
.successBtn2.active {
	color: #fff;
	background-color: #237804; /*color-8*/
	border-color: #237804; /*color-8*/
}
/* .ant-btn-primary {
	color: #fff;
	background-color: #1890ff; (color-6)
	border-color: #1890ff; (color-6)
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
	-webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
			box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  }
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background-color: #40a9ff; (color-5)
  border-color: #40a9ff; (color-5)
}
.ant-btn-primary:active,
.ant-btn-primary.active {
  color: #fff;
  background-color: #096dd9; (color-7)
  border-color: #096dd9; (color-7)
} */

.warningBtn {
	color: #fff;
	background-color: #fa8c16; /*color-6*/
	border-color: #fa8c16; /*color-6*/
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
	-webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}
.warningBtn:hover,
.warningBtn:focus {
	color: #fff;
	background-color: #ffa940; /*color-5*/
	border-color: #ffa940; /*color-5*/
}
.warningBtn:active,
.warningBtn.active {
	color: #fff;
	background-color: #d46b08; /*color-7*/
	border-color: #d46b08; /*color-7*/
}

.dangerBtn {
	color: #fff;
	background-color: #f5222d; /*color-6*/
	border-color: #f5222d; /*color-6*/
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
	-webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}
.dangerBtn:hover,
.dangerBtn:focus {
	color: #fff;
	background-color: #ff4d4f; /*color-5*/
	border-color: #ff4d4f; /*color-5*/
}
.dangerBtn:active,
.dangerBtn.active {
	color: #fff;
	background-color: #cf1322; /*color-7*/
	border-color: #cf1322; /*color-7*/
}

.headerProfileBtn {
	background-color: transparent !important;
	float: right;
	margin-top: 12px;
	padding: 4px 12px;
	height: 44px;
}
.headerProfileBtn:hover,
.headerProfileBtn:active,
.headerProfileBtn:focus {
	background-color: #2962ff !important;
}
.bg-warning {
	background-color: #efaf41;
}

.bg-danger {
	background-color: #e23c39;
}
.borderTopBlue {
	border: 0px;
	border-top: 1px solid #253e56;
}
.ant-layout-sider-zero-width-trigger {
	position: fixed;
	left: 200px;
	top: calc(100vh - 116px);
	border-radius: 24px;
	padding: 0px;
	padding-top: 2px;
	width: 42px;
	z-index: 1;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
	border-color: #2962ff;
	color: #2962ff;
}
.ant-menu-horizontal > .ant-menu-item-selected > a {
	color: #2962ff;
	/* font-weight: bold; */
}
.ant-layout-content {
	/* margin: 24px; */
	margin: auto;
	margin-bottom: 12px;
	width: 100%;
	max-width: 1100px;
	border-radius: 2px;
	/* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
}
.ant-table-pagination.ant-pagination {
	padding: 12px;
	margin: 0px;
	/* background: #e8e8e8; */
	background: #f5f5f5;
	width: 100%;
	text-align: center;
}
.ant-table-thead > tr > th {
	/* background: #e8e8e8; */
	background: #f5f5f5;
	border-color: #ccc;
}
.ant-table-tbody > tr > td {
	border-color: #ccc;
}
.ant-btn-primary {
	background-color: #2962ff;
}
.ant-btn-danger {
	background-color: #fff;
}

.ant-modal {
	color: #333;
}
.ant-collapse-header {
	font-weight: 500;
	padding-top: 16px !important;
	padding-bottom: 16px !important;
	color: #333;
	font-size: 16px;
	background: #fff;
	line-height: 24px;
}
.ant-checkbox-wrapper {
	color: #333;
}
.ant-checkbox-checked {
	background-color: #2962ff;
}
.ant-list-item-meta-title {
	color: #333;
}
.ant-list-split .ant-list-item {
	border-color: #ccc;
}
.ant-input {
	border-color: #ccc;
	color: #333;
}
.ant-select {
	color: #333;
}
.ant-btn {
	/* color: #333; */
}
.ant-menu-horizontal {
	border-color: #ccc;
}
.ant-divider {
	border-color: #ccc;
	background-color: #ccc;
}
.footer {
	background: #f5f5f5;
	/* background: #dfe3e6; */
	/* background: #efefef; */
	/* background: #e8e8e8; */
	border-top: 1px solid #ccc;
}
.ant-modal-footer {
	background: #f5f5f5;
	/* background: #dfe3e6; */
	/* background: #e8e8e8; */
	border-color: #ccc;
}
.ant-modal-header {
	background: #2962ff;
}
.ant-modal-title {
	color: #fff;
}
.ant-modal-close-x {
	color: #fff;
}
.cardHeaderSmall > .ant-card-head {
	padding: 0px 12px;
}
.cardHeaderSmall > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
	padding: 12px 0px;
}
.card1 {
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.listLink {
	text-decoration: none;
}
.listLink:focus {
	text-decoration: none;
	/* border-left: 2px solid #2962ff; */
	background: #e6f7ff;
}
.listLink:hover {
	background: #e6f7ff;
	/* border-left: 2px solid #e6f7ff; */
}
.activeChat {
	background: #e6f7ff;
	border-left: 2px solid #2962ff !important;
}
.chatBubbleAvatarContact {
	background: #2962ff;
	color: #fff;
}
.chatHide {
	display: block;
}
.hrBlue {
	border-color: #253e56;
}
.broadcastMessageDisplay {
	white-space: pre-wrap;
	border: 1px solid #d9d9d9;
	padding: 8px;
	border-radius: 4px;
	display: inline-block;
	margin-bottom: 8px;
	max-width: 260px;
}
.mAuto {
	margin: auto;
}
.dBlock {
	display: block;
}
.maxW {
	width: 100%;
}
.pointer {
	cursor: pointer;
}
.p0 {
	padding: 0px;
}
.p12 {
	padding: 12px;
}
.p24 {
	padding: 24px;
}
.pb4 {
	padding-bottom: 4px;
}
.pb8 {
	padding-bottom: 8px;
}
.pb12 {
	padding-bottom: 12px;
}
.pb16 {
	padding-bottom: 16px;
}
.pt16 {
	padding-top: 16px;
}
.cbpb12 .ant-card-body {
	padding-bottom: 12px;
}
.m0 {
	margin: 0px;
}
.mt4 {
	margin-top: 4px;
}
.mt8 {
	margin-top: 8px;
}
.mt12 {
	margin-top: 12px;
}
.mb0 {
	margin-bottom: 0px;
}
.mb8 {
	margin-bottom: 8px;
}
.mb12 {
	margin-bottom: 12px;
}
.mb14 {
	margin-bottom: 14px;
}
.mb24 {
	margin-bottom: 24px;
}
.ml0 {
	margin-left: 0px !important;
}
.ml8 {
	margin-left: 8px;
}
.ml12 {
	margin-left: 12px;
}
.mr0 {
	margin-right: 0px;
}
.mr4 {
	margin-right: 4px;
}
.mr8 {
	margin-right: 8px;
}
.mr12 {
	margin-right: 12px;
}
.mr16 {
	margin-right: 16px;
}
.mw300 {
	max-width: 300px;
}
.bt0 {
	border-top: 0px;
}
.bb {
	border-bottom: 1px solid #ccc;
}
.fleft {
	float: left;
}
.fright {
	float: right;
}
.fw500 {
	font-weight: 500;
}
.fwNormal {
	font-weight: normal;
}
.inlineBlock {
	display: inline-block;
}
.hide {
	display: none;
}
.bold {
	font-weight: bold;
}
.autoX {
	overflow-x: auto;
}
.taLeft {
	text-align: left;
}
.taCenter {
	text-align: center;
	justify-content: center;
}
.taRight {
	text-align: right;
}
.bgWhite {
	background: #fff;
}
.tWhite {
	color: #fff;
}
.tblack0 {
	color: #000;
}
.tblack {
	color: #000000a6;
}
.tgrey {
	color: #8c8c8c;
}
.tgrey2 {
	/* color: #aaa; */
	color: #8c8c8c;
}
.tgrey3 {
	/* color: #999; */
	color: #8c8c8c;
}
.tblue {
	color: #2962ff;
}
.tblue2 {
	color: #1890ff;
}
.tgreen {
	color: #52c41a;
}
.tred {
	color: #f5222d;
}
.tyellow {
	color: #fa541c;
}
.blue {
	background: #2962ff;
	color: #fff;
}
.material-icons {
	vertical-align: text-bottom;
	margin-bottom: -2px;
	margin-right: 8px;
}
.chipGrey {
	background: #fbfbfb;
	padding: 4px 8px;
	border-radius: 4px;
	border: 1px solid #ccc;
	margin-right: 4px;
	margin-bottom: 4px;
}
.App {
	text-align: center;
}

.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 40vmin;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}
.headerIcon {
}
.dataLoadingIcon {
	/* color: #000000a6; */
	color: #2962ff;
}
.fs12 {
	font-size: 12px;
}
.fs14 {
	font-size: 14px;
}
.fs15 {
	font-size: 15px;
}
.fs16 {
	font-size: 16px;
}
.fs18 {
	font-size: 18px;
}
.fs20 {
	font-size: 20px;
}
.fs24 {
	font-size: 24px;
}
.fs28 {
	font-size: 28px;
}
.ellip {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.tableFixed table {
	table-layout: fixed;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@media only screen and (max-width: 1000px) {
	.sideMenuLayout {
		padding-left: 0px;
		padding-bottom: 60px;
	}
	.sideMenuIcon {
		display: inline;
		margin-left: 2px;
		margin-right: 16px;
		/* margin-right: 40px; */
		opacity: 1;
	}
	.sideMenu {
		left: -250px;
		width: 250px !important;
		max-width: 250px !important;
	}
	.bottomNav {
		display: block;
	}
	.headerOrg {
		display: none;
	}
	.mobileHeaderOrg {
		display: block;
	}
	.headerTitleText {
		/* margin-left: 50px; */
	}
}

@media only screen and (max-width: 767px) {
	.chatHide {
		display: none;
	}
	.ant-modal {
		top: 0px;
	}
}

@media only screen and (max-width: 600px) {
	.headerIcon {
		display: none;
	}
}
